import i18n from '@/plugins/i18n'
import datasetSchoolYear from '@/datasets/schoolYear'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },

        {
          text: 'School Year',
          value: 'schoolYear',
        },
        // {

        //   value: 'delete',
        //   sortable: false,
        //   filterable: false,
        // },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'EventTopic',
        label:  'Topics',
      },

      {
        key: 'EventBook',
        label:  'Books',
      },

      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'School Year',
          value: 'schoolYear',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [{
      type: 'select',
      key: 'schoolYear',
      name: i18n.t("model.prop.schoolYear"),
      options: datasetSchoolYear,
      optionText: 'name',
      optionValue: 'id',
      defaultValue: 1,
    }, ],
    rules: {
      schoolYear: [v => !!v || 'School year is required'],
    },
  },
  edit: false,
  delete: true,
}